import React from 'react';
import { Box, Typography } from '@mui/material';

export interface Article {
  title: string;
  content: any;
}

export const articles: Article[] = [
  {
    title: 'What is Let\'s Give?',
    content: (
      <Box>
        <Typography>
        Let's Give is a secure and convenient mobile app designed specifically for Seventh Day Adventist Church organizations and individual users who want to manage their tithes and offerings in a simple and streamlined way. With Let's Give, church organizations can easily collect tithes and offerings online using a single QR code or link and view all transactions from their members in one place. They can also send their receipts in one place, making it easier to manage their finances and provide transparent reporting to their members.
        </Typography>
      </Box>
    )
  },
  // {
  //   title: 'What is Peer-To-Peer Transaction?',
  //   content: (
  //     <Box>
  //       <Typography>
  //         A peer-to-peer (P2P) payment transaction is a type of financial
  //         transaction where two parties exchange money directly with each other.
  //         With Paygr, you can easily send and receive payments directly from one
  //         person to another using peer-to-peer payment transactions. This means
  //         that you can quickly and conveniently transfer funds to friends,
  //         family, or anyone else without any intermediary or transaction fees.
  //       </Typography>
  //     </Box>
  //   )
  // },
  {
    title: 'What is Let\'s Give used for?',
    content: (
      <Box>
        <Typography>
          Let's Give simplifies the giving process by enabling Churches to generate a
          single link or QR code that can be shared with the church members.
          Regardless of the payment method they prefer, the Giver can easily
          use the link or QR code to make the payment, eliminating the need for
          a Giver and a Church to exchange payment information or worry about
          compatibility issues with different payment methods. With Let's Give, Church
          can easily facilitate payments without any complications, making the
          entire process seamless and stress-free.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Why should I use Let\'s Give?',
    content: (
      <Box>
        <Typography>
          • You should use Let's Give because it provides a centralized platform for
          managing online giving, making it easier for you to send and receive
          payments tithes and offerings from multiple sources. With Let's Give, a Church can create payment
          links and generate QR codes, making it simple and convenient for the
          givers to give. You can also track payment transactions in
          real-time and receive notifications to keep you informed about the
          status of your payments.
        </Typography>
        <Typography>
          • Let's Give supports multiple payment methods, including e-wallets like
          GCash, Maya, ShopeePay, GrabPay, Alipay, and Palawan Pay, as well as
          bank transfers from major banks in the Philippines like BDO, BPI,
          Metrobank, Unionbank, and many more.
        </Typography>
        <Typography>
          • Let's Give also has a chat feature that allows a Church and a Giver to communicate
          without leaving the app, making it easier for them to
          address any concerns or questions they may have about their payments.
        </Typography>
        <Typography>
          • Using Let's Give can help you streamline your payment processes and
          improve the overall payment experience for both the Church and the Giver.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What payment methods does Let\'s Give supports?',
    content: (
      <Box>
        <Typography>
          Let's Give supports multiple payment methods, including e-wallets such as
          GCash, Maya, ShopeePay, GrabPay, Alipay, and Palawan Pay, as well as
          bank transfers from major banks in the Philippines, such as BDO, BPI,
          Metrobank, Unionbank, and more. However, it's important to note that
          Let's Give does not process the actual payment transactions. Instead, it
          provides a platform for managing payments and allows users to link
          their preferred payment method to facilitate peer-to-peer
          transactions.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is required to use Let\'s Give?',
    content: (
      <Box>
        <Typography>
          To use Let's Give, you need to have a verified e-wallet or bank account
          that you can add to the app. This will enable you to receive payments
          from your customers using various payment methods such as e-wallets or
          bank transfers. You also need to have a smartphone or mobile device
          running on either iOS or Android operating system and download the
          Let's Give app from the App Store or Google Play Store. Additionally, you
          need a stable internet connection to access the app and make
          transactions.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What do I need to get started with Let\'s Give?',
    content: (
      <Box>
        <Typography>
          To get started with Let's Give, you need to download the Let's Give app from the
          App Store or Google Play Store, create an account using your Email,
          Google, Facebook, or Apple. Link your preferred payment methods such
          as e-wallets or bank accounts. Once you have linked your payment
          methods, you can start using Let's Give to send and receive tithes and offerings.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is Let\'s Give free?',
    content: (
      <Box>
        <Typography>
          Yes, Let's Give is free to use. However, there may be fees charged by the payment methods or banks
          used for the transactions, which are outside of Let's Give's control.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Does Let\'s Give have its own wallet system?',
    content: (
      <Box>
        <Typography>
          Let's Give does not have a wallet feature. It facilitates peer-to-peer
          payment transactions by providing a centralized platform for managing
          payments and supporting multiple payment methods, including e-wallets
          and bank transfers. However, it does not have its own wallet feature
          for storing funds.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is Let\'s Give connected to my online E-Wallets and Banks?',
    content: (
      <Box>
        <Typography>
          No, Let's Give is not directly connected to your online e-wallets and
          banks. Instead, Let's Give allows users to store their bank account details
          and supports multiple payment methods, including e-wallets such as
          GCash, Maya, ShopeePay, GrabPay, Alipay and Palawan Pay, and bank
          transfers from major banks in the Philippines like BDO, BPI,
          Metrobank, Union Bank, and more. This means that you can use Let's Give to
          manage your payments across different payment platforms and banks,
          without having to switch between different apps or websites.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is my e-wallet and bank balance reflected in Let\'s Give?',
    content: (
      <Box>
        <Typography>
          No, your e-wallet and bank balance are not reflected in Let's Give. Let's Give
          does not have access to your e-wallet or bank account balance.
          However, you can link your bank account or e-wallet to Let's Give to
          facilitate peer-to-peer transactions outside of the app. Let's Give
          provides a centralized platform for managing these transactions and
          allows you to track payment transactions in real-time, create payment
          links, generate QR codes for easy scanning, and view and manage all
          your payment transactions from different sources in one place.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Does Let\'s Give deduct money from my e-wallet and bank accounts?',
    content: (
      <Box>
        <Typography>
          No, Let's Give does not deduct money from your e-wallet or bank accounts.
          It only facilitates payments between the Church and the Giver by
          providing a platform to create payment links or generate QR codes.
          When a payment is made through Let's Give, the money goes directly to your
          designated bank account or e-wallet account, depending on the payment
          method selected by the Giver.
        </Typography>
      </Box>
    )
  },
  {
    title: 'How many payment options can a Church add in Let\'s Give?',
    content: (
      <Box>
        <Typography>
          There is no limit to the number of payment options that a church can add in
          Let's Give. You can add multiple bank accounts and e-wallets to your Let's Give
          account and use them to receive payments from the givers. This
          flexibility allows a church to offer their givers more payment options
          and makes it easier for them to send their tithes and offerings.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Is my data safe when I use Let\'s Give?',
    content: (
      <Box>
        <Typography>
          Yes, Let's Give takes data privacy and security seriously. The app uses
          encryption technology to protect all user information and
          transactions. Additionally, Let's Give does not store any sensitive
          information, such as bank account or e-wallet login credentials. The
          app also adheres to industry-standard security protocols to ensure
          that user data is safe and secure.
        </Typography>
      </Box>
    )
  },
  {
    title: 'Does Let\'s Give verify users?',
    content: (
      <Box>
        <Typography>
          No, Let's Give does not verify users directly as it is not a financial
          institution. However, users are required to link their verified
          e-wallet and bank accounts to the app in order to send and receive
          payments. This means that users must have already gone through the
          verification process with their e-wallet or bank provider in order to
          use Let's Give. Additionally, Let's Give has security measures in place to help
          prevent fraud and unauthorized access to user accounts.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What is the limitation of Let\'s Give?',
    content: (
      <Box>
        <Typography>
          1. Limited payment methods: Although Let's Give supports multiple payment
          methods, it does not support all payment methods available in the
          Philippines. For example, credit card payments are not supported.
        </Typography>
        <Typography>
          2. Peer-to-peer transactions only: Let's Give is designed for peer-to-peer
          transactions, so it may not be suitable for more
          advanced payment features such as invoicing or recurring payments.
        </Typography>
      </Box>
    )
  },
  {
    title: 'What if I need help on using Let\'s Give?',
    content: (
      <Box>
        <Typography>
          If you need help using Let's Give, you can reach out to their customer
          support team through the website chat feature. The team is available
          to assist you with any questions or issues you may have with the app.
          Additionally, Let's Give has a comprehensive FAQ and Help Center section on
          their website that covers many common questions and issues. You can
          also find helpful tutorials and guides on their social media pages and
          other online resources.
        </Typography>
      </Box>
    )
  }
];
