import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Container, Grid, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';
import Header from 'src/components/Header';
import TypingText from 'src/components/TypingText';
import { getPaymentOptionDetail } from 'src/views/profile/payment-method.util';
import { PaymentOption } from 'src/views/profile/profile.interface';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import LayeredHeroImage from './LayeredHeroImage';
interface HeroProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    paddingBottom: 40,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5
    }
  },
  content: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  text: {
    fontSize: 60,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 47,
      textAlign: 'center'
    }
  },
  animatedTextContainer: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  animatedText: {
    fontStyle: 'italic',
    fontWeight: 'bolder',
    marginLeft: 10,
    fontSize: 70,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 46
    }
  },
  tagline: {
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      textAlign: 'center'
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    // backgroundColor: 'gold',
    // perspectiveOrigin: 'left center',
    // transformStyle: 'preserve-3d',
    // perspective: 1500,
    width: '70%',
    '& > img': {
      maxWidth: '100%',
      height: 'auto'
      // transform: 'rotateY(-35deg) rotateX(15deg)',
      // backfaceVisibility: 'hidden',
      // boxShadow: theme.shadows[16]
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 100,
      width: '100%'
    }
  },
  downloadContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  downloadIcon: {
    display: 'flex',
    gap: 20,
    // justifyContent: 'center',
    // alignItems: 'center',
    '& > img': {
      width: 150,
      height: 'auto'
      // transform: 'rotateY(-35deg) rotateX(15deg)',
      // backfaceVisibility: 'hidden',
      // boxShadow: theme.shadows[16]
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  paymentOptionLogo: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  blobBox: {
    height: 100,
    [theme.breakpoints.down('md')]: {
      display: 'none',
      height: 'auto'
    }
  },
  blob: {
    position: 'relative',
    top: -206,
    right: 120,
    width: 1120,
    [theme.breakpoints.down('md')]: {
      top: '0'
    },
    [theme.breakpoints.down('sm')]: {
      right: '100%'
    }
  },
  heroLayerBox: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-60%, -50%)',
    zIndex: 20
  }
}));

const Hero: FC<HeroProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [textColor, setTextColor] = useState('#007dfe');
  const [isLoadingLayeredImage, setIsLoadingLayeredImage] = useState<boolean>(
    true
  );
  const [paymentOption, setPaymentOption] = useState<PaymentOption>(
    PaymentOption.gcash
  );
  const onClickPlayStore = () =>
    window.open(
      'https://play.google.com/store/apps/details?id=ph.titosolutions.letsgive',
      '_newtab'
    );
  const onClickAppStore = () =>
    window.open('https://apps.apple.com/ph/app/letsgive/id1665348167', '_newtab');

  const option = getPaymentOptionDetail({ option: paymentOption });
  const sequence = [
    'GCash',
    800,
    'Maya',
    () => setTextColor('black'),
    () => setPaymentOption(PaymentOption.maya),
    800,
    'PayPal',
    () => setTextColor('#002e80'),
    () => setPaymentOption(PaymentOption.paypal),
    800,
    'BDO',
    () => setTextColor('#0a2871'),
    () => setPaymentOption(PaymentOption.bdo),
    800,
    'UnionBank',
    () => setTextColor('#ec4317'),
    () => setPaymentOption(PaymentOption.unionBank),
    800,
    'PNB',
    () => setTextColor('#072269'),
    () => setPaymentOption(PaymentOption.pnb),
    800,
    'EastWest',
    () => setTextColor('#8c0b70'),
    () => setPaymentOption(PaymentOption.eastWestbank),
    800,
    'BPI',
    () => setTextColor('#a9021c'),
    () => setPaymentOption(PaymentOption.bpi),
    800,
    'Bitcoin',
    () => setTextColor('#f7931a'),
    () => setPaymentOption(PaymentOption.btc),
    800,
    'GrabPay',
    () => setTextColor('#09af4d'),
    () => setPaymentOption(PaymentOption.grabPay),
    800,
    'ShopeePay',
    () => setTextColor('#ed4c2f'),
    () => setPaymentOption(PaymentOption.shopeePay),
    800,
    'Metrobank',
    () => setTextColor('#00529e'),
    () => setPaymentOption(PaymentOption.metrobank),
    800,
    'SecurityBank',
    () => setTextColor('#00529e'),
    () => setPaymentOption(PaymentOption.securityBank),
    800,
    'Chinabank',
    () => setTextColor('#fe0000'),
    () => setPaymentOption(PaymentOption.chinabank),
    800,
    'AUB',
    () => setTextColor('#c2171f'),
    () => setPaymentOption(PaymentOption.aub),
    800,
    'RCBC',
    () => setTextColor('#3783d9'),
    () => setPaymentOption(PaymentOption.rcbc),
    1000,
    () => setTextColor('#007dfe'),
    () => setPaymentOption(PaymentOption.gcash)
  ];

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingLayeredImage(false);
    }, 1200);
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Box component="div" position="relative" zIndex={10}>
          <Header whiteLogo={false} />
        </Box>
        <Grid container spacing={{ xs: 12, lg: 3 }}>
          <Grid item xs={12} md={6}>
            <Fade left>
              <Box className={classes.content}>
                <Typography
                  className={classes.text}
                  color="#044954"
                  lineHeight={1.1}
                  maxWidth={'500px'}
                >
                  One QR for all Payment Methods
                </Typography>
                <Box
                  className={classes.animatedTextContainer}
                  color="white"
                  style={{
                    color: textColor
                  }}
                >
                  <img
                    alt="GCash"
                    src={option.imageUrl}
                    width={50}
                    height={50}
                    style={{ borderRadius: 5 }}
                  />
                  <Typography className={classes.animatedText}>
                    <TypingText
                      sequence={sequence}
                      style={{ fontWieght: 'regular' }}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.tagline} mt={4}>
                {/* <Typography variant="h4" color="#046473">
                  Blessed to Give, Let's Give!
                </Typography> */}
              </Box>
              <Box mt={10} className={classes.downloadContainer}>
                <Typography variant="h4" color="primary" mb={2}>
                  Download Now
                </Typography>
                <div className={classes.downloadIcon}>
                  <IconButton
                    aria-label="appstore"
                    component="label"
                    onClick={onClickAppStore}
                    sx={{ padding: 0 }}
                  >
                    <img
                      alt="Appstore"
                      src="/static/home/appstore_logo.png"
                      width={150}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="playstore"
                    component="label"
                    onClick={onClickPlayStore}
                    sx={{ padding: 0 }}
                  >
                    <img
                      alt="Play Store"
                      src="/static/home/google-play-logo.png"
                      width={150}
                    />
                  </IconButton>
                </div>
              </Box>
            </Fade>
          </Grid>
          <Grid item xs={12} md={6}>
            <Zoom>
              <Box component="div" className={classes.blobBox}>
                <Box component="div" className={classes.blob}>
                  <svg
                    width="1121"
                    height="1064"
                    viewBox="0 0 1121 1064"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.1746 118.622C-41.211 231.4 112.292 338.629 147.252 458.011C171.528 540.908 134.053 638.768 184.117 709.182C234.19 779.609 338.736 776.817 409.35 826.646C510.363 897.927 562.129 1053.91 685.414 1062.93C803.832 1071.59 900.715 960.77 974.698 867.986C1043.13 782.158 1060.58 671.544 1083.16 564.111C1105.05 459.894 1142.95 351.005 1104.16 251.789C1065.78 153.64 972.367 87.1622 878.174 39.8407C794.722 -2.08418 699.685 4.90287 606.363 1.70576C518.887 -1.29106 435.772 4.87419 349.9 21.7427C232.277 44.8479 61.6529 9.94987 11.1746 118.622Z"
                      fill="#CCE4E8"
                    />
                  </svg>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.heroLayerBox}
                  >
                    <Box
                      sx={{
                        opacity: isLoadingLayeredImage ? 0 : 1,
                        transition: 'opacity 2s'
                      }}
                    >
                      <LayeredHeroImage />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={{ xs: 'flex', md: 'none' }}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src="\static\home\hero\hero-section(v2).png"
                  width="100%"
                />
              </Box>
            </Zoom>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
