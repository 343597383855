import React from 'react';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {

  if (props?.whiteLogo) {
    return (
      <img
        alt="Logo"
        src="/static/logo.png"
        // src="/static/home/logo.png"
        {...props}
      />
    );
  }
  return (
    <img
        alt="Logo"
        src="/static/logo.png"
        {...props}
      />
  );
}

export default Logo;
