import { PaymentOption } from './profile.interface';

export const getPaymentOptionDetail = (method: any) => {
  const path = '/static/images/payment_methods';

  const option: PaymentOption = method?.option;
  switch (option) {
    case PaymentOption.cash: {
      return {
        type: 'p2p',
        title: 'Cash',
        imageUrl: `${path}/cash_logo.png`,
        data: {}
      };
    }
    case PaymentOption.gcash: {
      return {
        type: 'p2p',
        title: 'GCash',
        imageUrl: `${path}/gcash_logo@3x 1.png`,
        data: {
          label: 'Mobile Number',
          value: method?.data?.mobileNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.globe.gcash.android',
            url: 'market://details?id=com.globe.gcash.android'
          },
          ios: {
            url: 'gcash://'
          }
        }
      };
    }
    case PaymentOption.maya:
      return {
        type: 'p2p',
        title: 'Maya',
        imageUrl: `${path}/maya_logo-72x72.png`,
        data: {
          label: 'Mobile Number',
          value: method?.data?.mobileNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.paymaya',
            url: 'intent://scan/#Intent;scheme=paymaya;package=com.paymaya;end;'
          },
          ios: {
            url: 'paymaya://'
          }
        }
      };
    case PaymentOption.shopeePay:
      return {
        type: 'p2p',
        title: 'ShopeePay',
        imageUrl: `${path}/shopeepay_logo-72x72.png`,
        data: {
          label: 'Mobile Number',
          value: method?.data?.mobileNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.shopee.ph',
            url: 'market://details?id=com.shopee.ph'
          },
          ios: {
            url: 'shopeeph://'
          }
        }
      };
    case PaymentOption.grabPay:
      return {
        type: 'p2p',
        title: 'GrabPay',
        imageUrl: `${path}/grabpay_logo-72x72.png`,
        data: {
          label: 'Mobile Number',
          value: method?.data?.mobileNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.grabtaxi.passenger',
            url: 'market://details?id=com.grabtaxi.passenger'
          },
          ios: {
            url: 'grab://'
          }
        }
      };
    case PaymentOption.alipay:
      return {
        type: 'p2p',
        title: 'Alipay',
        imageUrl: `${path}/alipay_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.eg.android.AlipayGphone',
            url: 'market://details?id=com.eg.android.AlipayGphone'
          },
          ios: {
            url: 'alipay://'
          }
        }
      };
    case PaymentOption.palawanpay:
      return {
        type: 'p2p',
        title: 'Palawan Pay',
        imageUrl: `${path}/palawan_pay_logo.jpg`,
        marketUrl: {
          android: {
            appId: 'com.palawanpay.ewallet',
            url: 'market://details?id=com.palawanpay.ewallet'
          },
          ios: {
            url: null
          }
        }
      };

    case PaymentOption.bdo:
      return {
        type: 'p2p',
        title: 'BDO',
        imageUrl: `${path}/bdo_logo-72x72.png`,
        data: {
          label: 'Account Number',
          value: method?.data?.accountNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'www.mobile.bdo.com.ph',
            url: 'market://details?id=www.mobile.bdo.com.ph'
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.bpi:
      return {
        type: 'p2p',
        title: 'BPI',
        imageUrl: `${path}/bpi_logo-72x72.png`,
        data: {
          label: 'Account Number',
          value: method?.data?.accountNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.bpi.ng.mobilebanking',
            url: 'market://details?id=com.bpi.ng.mobilebanking'
          },
          ios: {
            url: 'bpiMobile://'
          }
        }
      };
    case PaymentOption.securityBank:
      return {
        type: 'p2p',
        title: 'Security Bank',
        imageUrl: `${path}/security_bank_logo-72x72.png`,
        data: {
          label: 'Account Number',
          value: method?.data?.accountNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.securitybank',
            url: 'market://details?id=com.securitybank'
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.unionBank:
      return {
        type: 'p2p',
        title: 'Union Bank',
        imageUrl: `${path}/union_bank_logo-72x72.png`,
        data: {
          label: 'Account Number',
          value: method?.data?.accountNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.unionbankph.online',
            url: 'market://details?id=com.unionbankph.online'
          },
          ios: {
            url: 'com.unionbankph.online://'
          }
        }
      };
    case PaymentOption.pnb:
      return {
        type: 'p2p',
        title: 'Philippine National Bank',
        imageUrl: `${path}/pnb-72x72.png`,
        data: {
          label: 'Account Number',
          value: method?.data?.accountNumber,
          name: method?.data?.name
        },
        marketUrl: {
          android: {
            appId: 'com.pnb.android',
            url: 'market://details?id=com.pnb.android'
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.chinabank:
      return {
        type: 'p2p',
        title: 'China Bank',
        imageUrl: `${path}/chinabank_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.cbc.mobilebanking',
            url: 'market://details?id=com.cbc.mobilebanking'
          },
          ios: {
            url: 'chinabank://'
          }
        }
      };
    case PaymentOption.eastWestbank:
      return {
        type: 'p2p',
        title: 'EastWest Bank',
        imageUrl: `${path}/eastwest_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.eastwest.mobile',
            url: 'market://details?id=com.eastwest.mobile'
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.landbank:
      return {
        type: 'p2p',
        title: 'Land Bank',
        imageUrl: `${path}/land_bank_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.landbank.mobilebanking',
            url: 'market://details?id=com.landbank.mobilebanking'
          },
          ios: {
            url: 'landbank://'
          }
        }
      };
    case PaymentOption.metrobank:
      return {
        type: 'p2p',
        title: 'Metrobank',
        imageUrl: `${path}/metro_bank_logo.png`,
        marketUrl: {
          android: {
            appId: 'ph.com.metrobank.metrobankdirectmobile',
            url: 'market://details?id=ph.com.metrobank.metrobankdirectmobile'
          },
          ios: {
            url: 'metrobank://'
          }
        }
      };
    case PaymentOption.rcbc:
      return {
        type: 'p2p',
        title: 'RCBC',
        imageUrl: `${path}/rcbc_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.rcbc.mobilebanking',
            url: 'market://details?id=com.rcbc.mobilebanking'
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.robinsonsbank:
      return {
        type: 'p2p',
        title: 'Robinsons Bank',
        imageUrl: `${path}/robinsons_bank_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.robinsons.RBankDigital',
            url: 'market://details?id=com.robinsons.RBankDigital'
          },
          ios: {
            url: 'rbankdigital://'
          }
        }
      };
    case PaymentOption.ingbank:
      return {
        type: 'p2p',
        title: 'ING Bank',
        imageUrl: `${path}/ing_bank_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.ing.banking',
            url: 'market://details?id=com.ing.banking'
          },
          ios: {
            url: 'BEPGenApp://'
          }
        }
      };
    case PaymentOption.aub:
      return {
        type: 'p2p',
        title: 'AUB',
        imageUrl: `${path}/aub_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.aub.mobilebanking',
            url: 'market://details?id=com.aub.mobilebanking'
          },
          ios: {
            url: 'aubmobileapp://'
          }
        }
      };
    case PaymentOption.helloMoney:
      return {
        type: 'p2p',
        title: 'Hello Money',
        imageUrl: `${path}/hello_money_logo.png`,
        marketUrl: {
          android: {
            appId: 'com.aub.hellomoney',
            url: 'market://details?id=com.aub.hellomoney'
          },
          ios: {
            url: 'hellomoney://'
          }
        }
      };

    case PaymentOption.binancePay:
      return {
        type: 'p2p',
        title: 'Binance Pay',
        imageUrl: `${path}/binance_pay_logo-72x72.png`,
        data: {
          label: 'Binance ID',
          value: method?.data?.binanceId,
          email: method?.data?.email
        },
        marketUrl: {
          android: {
            appId: 'com.binance.dev',
            url: 'market://details?id=com.binance.dev'
          },
          ios: {
            url: 'bnc://'
          }
        }
      };

    case PaymentOption.btc:
      return {
        type: 'p2p',
        title: 'Bitcoin (BTC)',
        imageUrl: `${path}/bitcoin-logo-png-transparent-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.eth:
      return {
        type: 'p2p',
        title: 'Ethereum (ETH)',
        imageUrl: `${path}/eth-diamond-black-gray-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.usdt:
      return {
        type: 'p2p',
        title: 'Tether (USDT)',
        imageUrl: `${path}/tether-usdt-logo-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.bnb:
      return {
        type: 'p2p',
        title: 'Binance Coin (BNB)',
        imageUrl: `${path}/bnb-bnb-logo-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.usdc:
      return {
        type: 'p2p',
        title: 'USD Coin (USDC)',
        imageUrl: `${path}/usd-coin-usdc-logo-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.xrp:
      return {
        type: 'p2p',
        title: 'Ripple (XRP)',
        imageUrl: `${path}/xrp-xrp-logo-72x72.png`,
        data: { label: 'Address', value: method?.data?.address },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };

    case PaymentOption.paypal:
      return {
        type: 'link',
        title: 'PayPal',
        imageUrl: `${path}/pp258-72x72.png`,
        data: { label: 'Username', value: method?.data?.username },
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'paypal://'
          }
        }
      };
    case PaymentOption.googlePay:
      return {
        type: 'link',
        title: 'Google Pay',
        imageUrl: `${path}/google_pay_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'https://gpay.app.goo.gl/'
          }
        }
      };
    case PaymentOption.cashapp:
      return {
        type: 'link',
        title: 'Cash App',
        imageUrl: `${path}/cash_app_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'https://cash.app/$'
          }
        }
      };
    case PaymentOption.venmo:
      return {
        type: 'link',
        title: 'Venmo',
        imageUrl: `${path}/venmo_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'https://venmo.com/'
          }
        }
      };
    case PaymentOption.strike:
      return {
        type: 'link',
        title: 'Strike',
        imageUrl: `${path}/strike_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'https://strike.me/'
          }
        }
      };
    case PaymentOption.zelle:
      return {
        type: 'link',
        title: 'Zelle',
        imageUrl: `${path}/zelle_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.revolut:
      return {
        type: 'link',
        title: 'Revolut',
        imageUrl: `${path}/revolut_logo.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: 'https://revolut.me/'
          }
        }
      };

    case PaymentOption.paypalGateway:
      return {
        type: 'paymentGateway',
        title: 'PayPal',
        imageUrl: `${path}/pp258-72x72.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.stripe:
      return {
        type: 'paymentGateway',
        title: 'Stripe',
        imageUrl: `${path}/stripe_logo-72x72.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.paymongo:
      return {
        type: 'paymentGateway',
        title: 'Paymongo',
        imageUrl: `${path}/paymongo_logo-72x72.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.xendit:
      return {
        type: 'paymentGateway',
        title: 'Xendit',
        imageUrl: `${path}/xendit_logo-72x72.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    case PaymentOption.dragonPay:
      return {
        type: 'paymentGateway',
        title: 'DragonPay',
        imageUrl: `${path}/dragonpay_logo-72x72.png`,
        marketUrl: {
          android: {
            appId: null,
            url: null
          },
          ios: {
            url: null
          }
        }
      };
    default:
      break;
  }
};
